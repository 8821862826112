/* Common Imports */

import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { setCreateNewPropertyCurrentPageThunk, setCreateNewServiceCurrentPageThunk } from "../../../redux-magic/store";

/* Component Imports */

import { SvgIcon, Typography, styled, useTheme } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from "@mui/system";
import CreatePropertyWithUploadTool from "../../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../../modals/ServiceModals/CreateServiceWithUploadTool";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";

/* Icons / Illustrations */

import ExplorePossibilities from "../../../public/images/icons/home/banners/get_into_real_estate.svg";
import PostProperty from "../../../public/images/icons/home/banners/post_property_mini_banner.svg";
import PostService from "../../../public/images/icons/home/banners/post_service_new.svg";
import { ArrowForwardOutlined } from "@mui/icons-material";

/* Styled Components */

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	margin: "0rem 0rem 0rem 0rem",
	[theme.breakpoints.up("md1190")]: {
		margin: "2rem 0rem 0rem 0rem",
	},
}));

const CarouselCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
}));

const BannerContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "locale",
})<{ locale: string }>(({ theme, locale }) => ({
	display: "flex",
	flexDirection: "row",
	direction: locale === "ar-AE" ? "rtl" : "ltr",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	minHeight: "5.125rem",
	height: "auto",
	gap: "0.5rem",
	margin: "0rem 0rem 2rem 0rem",
	borderRadius: "16px",
	background: "#fff7f0",
	// background:
	// 	locale === "ar-AE"
	// 		? "linear-gradient(90deg, rgba(252, 128, 25, 0.00) 0%, rgba(252, 128, 25, 0.19) 100%)"
	// 		: "linear-gradient(90deg, rgba(252, 128, 25, 0.19) 0%, rgba(252, 128, 25, 0.00) 100%)",
	[theme.breakpoints.up("md1190")]: {
		padding: "0.75rem 10rem 0.75rem 10rem",
	},
}));

const CarouselComponent = styled(Carousel)(({ theme }) => ({
	width: "100%",
	height: "100%",
	position: "relative",
	gap: "0rem",
	"& .carousel .control-dots": {
		position: "absolute",
		bottom: "0.25rem", // Move dots below the banner
		left: "50%",
		transform: "translateX(-50%)",
		margin: "0rem 0rem 0rem 0rem",
	},
	"& .carousel .control-dots .dot": {
		backgroundColor: "#d9d9d9",
		opacity: 1,
		margin: "0rem 0.25rem 0rem 0.25rem",
		boxShadow: "none",
		height: "0.5rem",
		width: "0.5rem",
	},
	"& .carousel .control-dots .dot.selected": {
		backgroundColor: "#b05911",
		height: "0.5rem",
		width: "1.25rem",
		borderRadius: "0.5rem",
	},
	"& .carousel .slide": {
		textAlign: "left",
	},
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	height: "100%",
	gap: "0.5rem",
	padding: "1rem 1rem 1rem 1rem",
	[theme.breakpoints.up("md1190")]: {
		// justifyContent: "space-between",
		gap: "1.5rem",
		padding: "1rem 0rem 0.5rem 0rem",
	},
}));

const Title = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	lineHeight: "1rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
	fontWeight: 500,
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.25rem",
	},
}));

const SubTtext = styled(Typography)(({ theme }) => ({
	fontSize: "0.675rem",
	lineHeight: "0.95rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#212121",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

const BannerCarouselOne = ({
	session,
	dispatch,
	new_listing_url,
	newPropertyData,
	newServiceData,
}: {
	session: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	newServiceData: StateInterface["new_service_data"];
}) => {
	const theme = useTheme();

	const router = useRouter();

	const isDesktop = useMediaQuery(theme.breakpoints.up("md1190"));

	const localLang = {
		real_estate_marketplace:
			router.locale === "ar-AE"
				? "سوق العقارات"
				: router.locale === "kn-IN"
					? "ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಮಾರುಕಟ್ಟೆ"
					: "Real estate marketplace",
		real_estate_marketplace_subtitle:
			router.locale === "ar-AE"
				? "بسِّط رحلتك العقارية مع بيجرو!"
				: router.locale === "kn-IN"
					? "ಬೀಗ್ರು ಸಹಿತ ನಿಮ್ಮ ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಪ್ರಯಾಣವನ್ನು ಸುಲಭಗೊಳಿಸಿ!"
					: "Simplify your real estate journey with Beegru!",
		real_estate_marketplace_action:
			router.locale === "ar-AE" ? "اعرف المزيد" : router.locale === "kn-IN" ? "ಹೆಚ್ಚು ತಿಳಿಯಿರಿ" : "Know more",
		post_property:
			router.locale === "ar-AE"
				? "نشر عقارك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಆಸ್ತಿ ಪೋಸ್ಟ್ ಮಾಡಿ"
					: "Post your property",
		post_property_subtitle:
			router.locale === "ar-AE"
				? "بع أو أجر عقارك على منصتنا!"
				: router.locale === "kn-IN"
					? "ನಮ್ಮ ವೇದಿಕೆಯಲ್ಲಿ ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಮಾಡಿ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ!"
					: "Sell or rent out your property on our platform!",
		post_property_action:
			router.locale === "ar-AE" ? "نشر عقارك" : router.locale === "kn-IN" ? "ಆಸ್ತಿ ಪೋಸ್ಟ್ ಮಾಡಿ" : "Post property",
		post_service:
			router.locale === "ar-AE"
				? "نشر خدمتك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
					: "Post your service",
		post_service_subtitle:
			router.locale === "ar-AE"
				? "هل أنت مقدم خدمة؟ انشر خدمتك على منصتنا!"
				: router.locale === "kn-IN"
					? "ನೀವು ಸೇವಾ ಪೂರೈಕೆದಾರರಾ? ನಮ್ಮ ವೇದಿಕೆಯಲ್ಲಿ ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ!"
					: "Are you a service provider? Post your service on our platform!",
		post_service_action:
			router.locale === "ar-AE"
				? "نشر خدمتك"
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
					: "Post service",
	};

	const [autoplay, setAutoplay] = React.useState(true);

	const [create, setCreate] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("business_profile"));
		setCreate(true);
	};

	const handleCreateClose = () => {
		setCreate(false);
	};

	const [openservice, setOpenService] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("business_profile"));
		setOpenService(true);
	};

	const handleCloseNewService = () => {
		setOpenService(false);
	};

	const pathname = React.useRef(router).current;

	/*

		* pathname create property trigger state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true) {
			session && session.authorized ? setCreate(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

		* pathname create service trigger service state to open

	*/

	React.useEffect(() => {
		if (pathname.query.create_service === "true" && session === true) {
			session && session.authorized ? setOpenService(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	/*

	* Close state and remove query from url

	*/

	React.useEffect(() => {
		if (pathname.query.create_property === "true" && session === true && create === false) {
			window.history.pushState({ urlPath: "/?create_property=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [create]);

	React.useEffect(() => {
		if (pathname.query.create_service === "true" && session === true && openservice === false) {
			window.history.pushState({ urlPath: "/?create_service=true" }, "", "/");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openservice]);

	const banners = [
		{
			title: localLang.real_estate_marketplace,
			subtitle: localLang.real_estate_marketplace_subtitle,
			icon: ExplorePossibilities,
			button_label: localLang.real_estate_marketplace_action,
		},
		{
			title: localLang.post_property,
			subtitle: localLang.post_property_subtitle,
			icon: PostProperty,
			button_label: localLang.post_property_action,
		},
		{
			title: localLang.post_service,
			subtitle: localLang.post_service_subtitle,
			icon: PostService,
			button_label: localLang.post_service_action,
		},
	];

	const handleBannerClick = (index: number) => {
		if (index === 0) {
			window.location.href = "loading.html?callbackUrl=" + encodeURIComponent("/" + router.locale + "/intro");
		} else if (index === 1) {
			// router.push("/?create_property=true");
			session !== null
				? handleCreateOpen()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_property: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		} else if (index === 2) {
			// router.push("/?create_service=true");
			session !== null
				? handleOpenNewService()
				: router
						.push(
							{
								pathname: encodeURI("/"),
								query: { create_service: "true" },
							},
							{
								pathname: encodeURI("/"),
								query: { create_service: "true" },
							},
							{ locale: router.locale },
						)
						.then(() => {
							signIn();
						});
		}
	};

	return (
		<MainContainer>
			<CreatePropertyWithUploadTool
				open={create}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
			/>

			<CreateServiceWithUploadTool
				open={openservice}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			<CarouselComponent
				autoPlay={autoplay}
				showArrows={false}
				showThumbs={false}
				swipeable={true}
				showStatus={false}
				infiniteLoop={true}
				interval={5000}
				showIndicators={true}
				onSwipeStart={() => setAutoplay(false)}
				onSwipeEnd={() => setAutoplay(true)}
				transitionTime={500}
			>
				{banners.map((banner, index) => (
					<CarouselCard
						id={"home_mobile_banner_" + banner.title}
						key={index}
						onClick={() => {
							handleBannerClick(index);
						}}
					>
						<BannerContainer locale={router.locale ? router.locale : "en-IN"}>
							<TextContainer>
								<Title
									variant="h6"
									sx={{
										fontWeight: router.locale === "ar-AE" ? 600 : 500,
									}}
								>
									{banner.title}
								</Title>
								<SubTtext
									sx={{
										textAlign: router.locale === "ar-AE" ? "right" : "left",
									}}
								>
									{banner.subtitle}
								</SubTtext>
								<BeegruButton
									flavor="success"
									variant="outlined"
									size="small"
									sx={{
										margin: "0.25rem 0rem 0rem 0rem",
										"&.MuiButton-outlined": {
											color: "#b05911",
											border: `1px solid #b05911`,
											"&:hover": {
												background: "transparent",
											},
											"&:active": {
												background: "transparent",
											},
											"&:focus": {
												background: "transparent",
											},
											fontSize: "0.75rem",
											[theme.breakpoints.up("md1190")]: {
												fontSize: "0.875rem",
											},
										},
									}}
									onClick={(e: any) => {
										e.stopPropagation();
										handleBannerClick(index);
									}}
									disableElevation
									disableRipple
									disableFocusRipple
									disableTouchRipple
									disableHoverRipple
									disableInteractive
									disableTouchListener
									disableFocusListener
								>
									{banner.button_label}
									<ArrowForwardOutlined
										sx={{
											margin: "0rem 0rem 0rem 0.25rem",
											fontSize: "1rem",
											[theme.breakpoints.up("md1190")]: {
												fontSize: "0.875rem",
											},
										}}
									/>
								</BeegruButton>
							</TextContainer>

							<SvgIcon
								component={banner.icon}
								inheritViewBox={false}
								viewBox={index === 0 ? "0 0 141 71" : index === 1 ? "0 0 136 93" : "0 0 128 109"}
								sx={{
									width:
										index === 0
											? isDesktop
												? "14rem"
												: "8rem"
											: index === 1
												? isDesktop
													? "11rem"
													: "7rem"
												: isDesktop
													? "10rem"
													: "6rem",
									height:
										index === 0
											? isDesktop
												? "10rem"
												: "4rem"
											: index === 1
												? isDesktop
													? "10rem"
													: "4rem"
												: isDesktop
													? "10.5rem"
													: "4.5rem",
								}}
							/>
						</BannerContainer>
					</CarouselCard>
				))}
			</CarouselComponent>
		</MainContainer>
	);
};

export default BannerCarouselOne;
