/* Common Imports */

import React from "react";

/* Component Imports */

import { Button, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/system";

/* Before we begin, let's define a custom attribute for our container called index. This will help the layout container organise our component correctly. */

type CustomAttributesForDivElement = {
	index?: number;
};

const SectionContainer = styled("div")<CustomAttributesForDivElement>(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem 0rem 0rem 0rem",
}));

const Text = styled(Typography)(({ theme }) => ({
	display: "flex",
	width: "100%",
	justifyContent: "flex-start",
	fontSize: "1rem",
	fontWeight: "400",
	lineHeight: "1rem",
	textTransform: "capitalize",
	color: theme.palette.text.primary,
	[theme.breakpoints.down("sm")]: {
		fontSize: "0.875rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
	fontWeight: 400,
	[theme.breakpoints.down("sm")]: {
		fontSize: "0.875rem",
	},
}));

const CheckBoxContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	flexWrap: "nowrap",
	gap: "0.5rem",
	width: "100%",
	overflowX: "auto",
	"&::-webkit-scrollbar": {
		display: "none",
	},
	[theme.breakpoints.up("md1190")]: {
		gap: "1rem",
	},
}));
const CheckBoxDiv = styled(Button)(({ theme }) => ({
	display: "inline-flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "0.5rem",
	// padding: "var(--Spacing-spacing-xs, 4px)",\
	cursor: "pointer",
	whiteSpace: "nowrap",
	flexShrink: 0,
}));
const RecentSearch = ({ recentSearchData }: { recentSearchData: any }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<SectionContainer>
				<Heading>Recent searches</Heading>
				<CheckBoxContainer>
					{recentSearchData?.map((data: any, index: number) => {
						return (
							<CheckBoxDiv
								id={"homepage_recent_search"}
								key={index}
								disableRipple
								disableTouchRipple
								href={data.url}
								sx={{
									display: "flex",
									border: "1px solid #C0C0C0",
								}}
							>
								<Text variant="body1">{data.content}</Text>
							</CheckBoxDiv>
						);
					})}
				</CheckBoxContainer>
			</SectionContainer>
		</React.Fragment>
	);
};

export default RecentSearch;
